import React from 'react'
import styled from 'styled-components'

const ListStyle = styled.ul`
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    .list-image {
        position: absolute;
        .list-item-image {
            width: 250px;
        }
    }
    .list-info {
        min-height: 150px;
        padding-left: 250px;
        width: 350px;
        font-size: 14px;
        font-weight: 300;
        margin-left: 15px;
        .list-item {
            width: 100%;
            display: flex;
            margin-bottom: 15px;
            .left {
                width: 100px;
                font-weight: 400;
                color: var(--gray-color-2);
            }
            .right {
                width: 230px;
                &.models {
                    overflow-y: scroll;
                    height: 100px;
                    .model {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        flex-direction: column;
        .list-image {
            position: static;
            margin: auto;
            margin-bottom: 15px;
        }
        .list-info {
            padding: 0;
            .list-item {
                .left {
                    width: 80px;
                }
            }
        }
    }
`

function ProductList({productname, maker, models, imgSrc}) {
    return (
        <ListStyle>
            <div className='list-image'>
                <img src={imgSrc} className='list-item-image' alt='適合車種　写真' />
            </div>
            <div className='list-info'>
                <li className='list-item first'>
                    <div className='left'>商品コード</div>
                    <div className='right'>{productname}</div>
                </li>
                <li className='list-item'>
                    <div className='left'>メーカー</div>
                    <div className='right'>{maker}</div>
                </li>
                <li className='list-item last'>
                    <div className='left'>対応車種</div>
                    <div className='right models'>
                        {models.map((model, index) => (
                            <div className='model' key={index}>{model}</div>
                        ))}
                    </div>
                </li>
            </div>
        </ListStyle>
    )
}

export default ProductList



