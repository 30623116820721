import React from 'react'
import styled from 'styled-components'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const CategoryNavStyle = styled.div`
    margin: 40px 0;
    font-family: var(--gothic);
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    .category-nav-title {
        color: var(--gray-color-2);
        font-size: 20px;
        display: flex;
        align-items: center;
        margin-right: 15px;
        letter-spacing: 0.05em;
    }
    .Dropdown-root {
        .Dropdown-control {
            height: 45px;
            width: 300px;
            display: flex;
            align-items: center;
            .Dropdown-arrow-wrapper {
                .Dropdown-arrow {
                    top: 20px;
                }
            }
            .Dropdown-placeholder {
                letter-spacing: 0.1em;
            }
        }
    }
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        .category-nav-title {
            margin-bottom: 15px;
        }
    }
`

function CategoryNav({categories, setCurrent}) {
    const options = []
    categories.forEach(category => {
        options.push(category.model)
    })
    const defaultOption = options[0]
    const dropDownAction = (e) => {
        setCurrent(categories.filter((cat) => cat.model===e.value)[0])
    }
    return (
        <CategoryNavStyle>
            <div className='category-nav-title'>適合する車種を探す:</div>
            <Dropdown options={options} onChange={e => dropDownAction(e)} value={defaultOption} placeholder="Select an option" />
        </CategoryNavStyle>
    )
}

export default CategoryNav


