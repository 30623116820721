import React from 'react'
import styled from 'styled-components'
import Video from '../common/video'

const CategoryTopDescriptionStyle = styled.div`
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    .video {
        margin: 0 15px;
        width: 100%;
    }
    .category-top-description {
        margin: 0 15px;
        font-family: var(--gothic);
        font-weight: 300;
        font-size: 14px;
        line-height: 1.5;
        .title {
            font-weight: 600;
        }
        .description {
            margin: 15px 0;
        }
    }
    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
        .category-top-description {
            margin-top: 20px;
        }
    }

`

function CategoryTopDescription() {
    return (
        <CategoryTopDescriptionStyle>
            <div className="video">
                <Video
                    videoSrcURL="https://www.youtube.com/embed/afa9L2ocZPY"
                    videoTitle="Official Video on YouTube"
                />
            </div>
            <div className='category-top-description'>
                {desc.map(d => (
                    <>
                      <div className='title'>{d.title}</div>
                      <div className='description'>{d.description}</div>
                    </>
                ))}
            </div>
        </CategoryTopDescriptionStyle>
    )
}

export default CategoryTopDescription

const desc = [
    { title: '縁石や路面のギャップに対する路面追従性の向上', description: '走行中車体に対し、縁石や路面のギャップから何らかの入力があった際、その入力をオーバーサスペンションが相殺するためタイヤの路面追従性が向上します。' },
    { title: '車体のコントロール性の向上', description: 'リアサスペンションの余分な動きを軽減します。車体がバンク状態から立ち上がるにつれ、スイングアームはリアサスペンションが伸びる方向に引っ張られます。さらにコーナーでは遠心力と加速時のトルクが加わればリアサスペンションは縮みます。これら相反する動作においてもオーバーサスペンションが素直に反応します。'}
]
