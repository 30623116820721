import React from 'react'
import styled from 'styled-components'
import ProductList from './ProductList'

const CategoryProductInfoStyle = styled.section`
    margin-top: 40px;
    font-family: var(--gothic);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 200px;
    .category-info-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin: auto;
        .category-info-list {
            height: 100%;
            width: 100%;
            padding: 30px 0;
            border-bottom: solid 1px var(--gray-color-3);
            &:last-child {
                border: none;
            }
        }
    }
`
function CategoryProductInfo({price, products}) {
    return (
        <CategoryProductInfoStyle>
            <h3 className='current-price'>価格:　{addPriceDot(price)}円 (税別 {addPriceDot(withoutTax(price))}円)</h3>
            <div className='category-info-container'>
                {products.map((product,index) => {
                    if (product.photo[0].fluid.src !== undefined) {
                        return (
                            <div className='category-info-list'>
                                <ProductList
                                    key={index}
                                    productname={product.productname}
                                    maker={product.maker}
                                    models={product.model}
                                    imgSrc={product?.photo[0]?.fluid.src}
                                />
                            </div>
                        )
                    }
                    return (
                        <div key={index}></div>
                    )
                })}
            </div>
        </CategoryProductInfoStyle>
    )
}

export default CategoryProductInfo

function addPriceDot(price) {
    const priceString = price.toString()
    if (priceString.length > 3) {
        return priceString.slice(0,-3) + "," + priceString.slice(-3, priceString.length)
    }
    return price
}

function withoutTax(price) {
    const intPrice = parseInt(price)
    return parseInt(intPrice / 1.1)
}