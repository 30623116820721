import React from 'react'
import styled from 'styled-components'

const ManualDownloadStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    .manual-download {
        font-family: var(--gothic);
        color: rgb(207, 27, 27);
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        transition: var(--transition-timing);
        &:hover {
            opacity: 0.5;
        }
    }
    @media screen and (max-width: 700px) {
        padding: 15px 0 25px;
    }
`

function ManualDownload() {
    return (
    <ManualDownloadStyle>
        {/* <a href={``} download rel="noreferrer" className='manual-download'＞マニュアルのダウンロードする</a> */}
        <a rel="noreferrer" className='manual-download' onClick={() => alert("現在準備中です。しばらくお待ち下さい")}>＞製品マニュアルをダウンロード</a>
    </ManualDownloadStyle>
    )
}

export default ManualDownload
