import React, { useEffect, useState } from 'react'
import Layout from '../components/layout/layout'
import PageTop from '../components/common/PageTop'
import Title from '../components/common/Title'
import PageComponent from '../components/common/PageComponent'
import CategoryProducts from '../components/category/CategoryProducts'
import CategoryTopDescription from '../components/category/CategoryTopDescription'
import ManualDownload from '../components/category/ManualDownload'

const pageComponentStyle = {
    background: '#fff',
    padding: 0
}
const containerStyle = {
    maxWidth: '1000px',
    margin: '0 auto',
}

function ProductPage() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [width, setWidth] = useState();
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        if (!isLoaded) {
            setIsLoaded(true)
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <Layout pageTitle="製品紹介">
            {width && (
                <PageTop title='PRODUCT' />
            )}
            <PageComponent styles={pageComponentStyle} containerStyles={containerStyle}>
                <Title title='製品紹介' subtitle='オーバーサスペンションについて' />
                <CategoryTopDescription />
                <ManualDownload />
                <Title title='オーバーサスペンションを探す' />
                <CategoryProducts />
            </PageComponent>
        </Layout>
    )
}

export default ProductPage
