import React from 'react'
import styled from 'styled-components'


const ButtonStyle = styled.div`
    cursor: pointer;
    width: 400px;
    height: 90px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px var(--gray-color-1);
    .button-image {
        transition: var(--transition-timing);
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.5) contrast(1.3);
    }
    .button-text {
        z-index: 10;
        font-weight: 600;
        font-size: 16px;
        color: var(--white-color-1);
        letter-spacing: 0.05em;
        a {
            z-index: 10;
            font-weight: 600;
            font-size: 16px;
            color: var(--white-color-1);
            letter-spacing: 0.05em;
        }
    }
    &:hover {
        .button-image {
            filter: brightness(0.6) contrast(1.3);
        }
        .button-text {
            color: var(--white-color-1);
            a {
                color: var(--white-color-1);
            }
        }
    }
`

function Button({children, imgSrc}) {
    return (
        <ButtonStyle>
            <img src={imgSrc} alt={'オーバーサスペンション　商品'} className='button-image'/>
            <p className='button-text'>{children}</p>
        </ButtonStyle>
    )    
}
export default Button