import React from "react"
import styled from "styled-components"

const VideoStyle = styled.div`
  width: 453px;
  height: 255px;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 220px;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
`

const Video = ({ videoSrcURL, videoTitle }) => (
  <VideoStyle>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VideoStyle>
)
export default Video