import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { ProductsContext } from '../../contexts/StateProvider'
import CategoryProductInfo from './CategoryProductInfo'
import CategoryNav from './CategoryNav'
import VisualButton from './VisualButton'
import imgSrc from '../../assets/images/visualbutton_compressed.jpg'
import imgSrc2 from '../../assets/images/visualbutton2_compressed.jpg'

const CategoryStyle = styled.section`
    .category-products-title {
        font-family: var(--gothic);
        font-size: 20px;
        color: var(--gray-color-2);
    }
    .category-buttons {
        padding: 20px 0;
        display: flex;
        justify-content: center;
        * {
            margin: 0 20px;
        }
        @media screen and (max-width: 680px) {
            * {
                margin: 10px 0;
            }
            flex-wrap: wrap;
        }
    }
`

function CategoryPage() {
    const data = useContext(ProductsContext)
    let categories = data ? data[0] : undefined
    let products = data ? data[1]: undefined
    const [currentCategory, setCurrentCategory] = useState(null)
    const [currentProducts, setCurrentProducts] = useState(null)
    const [currentImageSrc, setCurrentImageSrc] = useState(null)
    const [currentPrice, setCurrentPrice] = useState(null)
    function setCurrent(category) {
        if (category) {
            setCurrentCategory(category.model)
            setCurrentImageSrc(category.picture.fluid.src)
            setCurrentPrice(category.price)
        }
        if (products[category.model]) {
            setCurrentProducts(Object.values(products[category.model]))
        } else {
            setCurrentProducts([])
        }
    }
    useEffect(() => {
        if (categories) {
            setCurrent(categories[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories])
    return (
        <CategoryStyle>
            <div className='category-buttons'>
                <VisualButton imgSrc={imgSrc}>
                    <a href='https://shop.aella.jp/item/IS00159N11207.html' target="_blank" rel="noopener" >製品の購入はこちら</a>
                </VisualButton>
                <VisualButton imgSrc={imgSrc2}>
                    <a href={`/ovesuspension.pdf`} download rel="noreferrer">適合車種の一覧をダウンロード</a>
                </VisualButton>
            </div>
            {categories && (
                <CategoryNav categories={categories} setCurrent={setCurrent} />
            )}
            {currentCategory && currentProducts && (
                <CategoryProductInfo 
                    category={currentCategory} 
                    imageSrc={currentImageSrc} 
                    price={currentPrice} 
                    products={currentProducts}
                />
            )}
        </CategoryStyle>
    )
}

export default CategoryPage





